import { ApplicationService, initialize } from "@verified/sspa-core-lib";
import {
  addErrorHandler,
  getAppStatus,
  registerApplication,
  start,
} from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";

addErrorHandler((err) => {
  console.log(err);
  console.log(err.appOrParcelName);
  console.log(getAppStatus(err.appOrParcelName));
});

const routes = constructRoutes(document.querySelector("#single-spa-layout"), {
  loaders: {
    default: `<div class="loader"><span>loading...</span></div>`,
  },
  props: {
    env: global.env,
  },
  errors: {
    default: {
      bootstrap: async (a) => {},
      mount: async ({ domElement, error }) => {
        return (domElement.innerHTML = `<div class="error" style="background-color: red; text-color: white; padding:5px; margin:0px">Error: <strong>${error.appOrParcelName}</strong> could not be loaded.</div>`);
      },
      unmount: async ({ domElement }) => {
        domElement.innerHTML = "";
      },
    },
    genericError: "<h1>Oops! It looks like it doesn't work right now.</h1>",
  },
});

routes.base = global.env?.pathPrefix || "";
console.log("Routes", routes);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

initialize({ ...global.env, routes: routes.routes });
const application = ApplicationService.getInstance();

applications.forEach((app) => application.register(app));

start();
